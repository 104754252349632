import type { FC } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, openJivoChat, selectUserIsAuthenticated, useAccessCookie, useQueryPopupOpen } from '@mwl/core-lib';
import { getTestProps } from '@mwl/marker-tree';
import { Accordion } from '@/components/Accordion/Accordion';
import type { AccordionClasses } from '@/components/Accordion/Accordion.types';
import type { ButtonProps } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';
import { Download } from '@/components/Download/Download';
import type { DownloadClasses } from '@/components/Download/Download.types';
import { Image } from '@/components/Image/Image';
import { useMediaWidthDown, useTypedDispatch, useTypedSelector } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';
import { userMenuObject } from '@/utils';
import { LocaleButton } from '../../../Header/components/LocaleButton/LocaleButton';
import type { ClickHandler, SidebarMenuItem } from '../../Sidebar.types';
import type { MenuProps } from './Menu.types';
import styles from '../../Sidebar.module.scss';
const accordeonClasses: AccordionClasses = {
  button: styles.sidebarAccordeonItemButton
};
const downloadClasses: DownloadClasses = {
  button: styles.downloadButton
};
const Menu: FC<MenuProps> = ({
  elements: data,
  className = styles.itemLink
}) => {
  const {
    t
  } = useTranslation();
  const dispatch = useTypedDispatch();
  const {
    createLinkProps
  } = useQueryPopupOpen();
  const isSmallScreen = useMediaWidthDown('screenXs');
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const hasWheelCookie = useAccessCookie('wheel');
  const clickHandlers: Record<ClickHandler, () => void> = {
    support: openJivoChat
  };
  const handleClickLink = (item?: SidebarMenuItem) => {
    dispatch(toggleSidebar(false));
    if (item && item?.clickHandlerType) {
      clickHandlers[item.clickHandlerType]?.();
    }
  };
  return <>
      {data.map((item, index) => {
      if ('isDownloadBlock' in item) {
        return <Fragment key={index}>
              <Download classes={downloadClasses} analytics={item?.analytics} />

              <div className={styles.localeButton}>
                <LocaleButton testMarker={userMenuObject.localeSelector} />
              </div>
            </Fragment>;
      }
      if ('isDivider' in item) {
        return <div className={styles.divider} key={index} />;
      }
      if (item.onlyMobile && !isSmallScreen) {
        return null;
      }
      if (item.onlyDesktop && isSmallScreen) {
        return null;
      }
      const icon = item.icon && <div className={styles.icon} key={index}>
            <Image src={item.icon} width={20} height={20} alt={item.label ? t(item.label) : ''} />
          </div>;
      if ('isLuckyWheelBlock' in item) {
        const wheelProps = createLinkProps({
          queryName: 'wheel'
        });
        if (!hasWheelCookie) {
          return null;
        }
        return <Button {...wheelProps} as="link" variant="text" className={className} key={index} onClick={() => handleClickLink()}>
              {icon}
              {item.label ? t(item.label) : ''}
            </Button>;
      }
      if ('children' in item) {
        const content = <div key={index} className={cn(styles.subItemLinks, {
          [styles.staticGroup]: item.static
        })}>
              <Menu elements={item.children} className={styles.subItemLink} />
            </div>;
        if (item.static) {
          return content;
        }
        return <Fragment key={index}>
              {item.header && <h2 className={styles.blockHeader}>{t(item.header)}</h2>}
              <Accordion classes={accordeonClasses} title={<div {...getTestProps(item)} className={styles.itemHeader}>
                    {icon}
                    {item.label ? t(item.label) : ''}
                  </div>} opened={item.opened}>
                {content}
              </Accordion>
            </Fragment>;
      }
      let buttonProps: ButtonProps = item.clickHandlerType ? {
        as: 'button'
      } : {
        as: 'link',
        href: item.link
      };
      if (item.onlyAuth && !isAuth) {
        buttonProps = {
          as: 'link',
          ...createLinkProps({
            queryName: 'register'
          })
        };
      }
      return <Button {...getTestProps(item)} {...buttonProps} variant="text" className={className} key={index} onClick={() => handleClickLink(item)} analytics={extendAnalyticsData(item.analytics, {
        text: item.label,
        cls: className
      })}>
            {icon}
            {item.label ? t(item.label) : ''}
          </Button>;
    })}
    </>;
};
export { Menu };